@media screen and (min-width: 1600px) {
    .sidebanner {
        width: 100%;
        flex-wrap: wrap;
    }

    .mainLogsContined {
        max-width: 100%;
        max-width: 100%;
        width: 100%;
    }

    .whitelogos {
        margin: auto;
    }
}
@media screen and (max-width: 1500px) {
    .mainBanner {
        background-size: auto 100%;
    }
}
/* @media screen and (max-width: 1400px) {
    .logo-text {
        display: flex;
        align-items: center;
        gap: 8px;
        position: absolute;
        left: 42.5%;
        top: 16%;
    }
} */
@media screen and (min-width: 1200px) and (max-width: 1400px) {
    .mobile-sec .container {
        max-width: 1250px;
    }
}
@media screen and (max-width: 1300px) {
    .mainBanner {
        padding: 60px 0;
    }
    section {
        padding: 40px 0;
    }
    .problembanner.banner2 img {
        width: 100%;
    }
    .solution,
    .aboutprocess,
    .subsplans,
    .worldwide {
        background-size: auto 100%;
    }
    footer .textparts,
    footer .textparts span {
        font-size: 32px;
        line-height: normal;
    }
    .mainBanner {
        background-size: cover;
    }
}
@media screen and (max-width: 1200px) {
    .left-wrap {
        margin-bottom: 30px;
    }
    .logo-text {
        display: none;
    }
}
@media screen and (max-width: 1100px) {
    .btnsgroups.aboutUs {
        flex-wrap: wrap;
    }
    ul.listDesign li {
        font-size: 12px;
        line-height: normal;
    }
    .cardblock {
        flex-wrap: wrap;
    }
    .cardblock .cardsubs {
        width: 48.9%;
    }
    h2.headings {
        font-size: 32px;
        margin-bottom: 8px;
        line-height: normal;
    }
    .cardblock .cardsubs .geryblocked ul li {
        max-width: 100%;
    }
    p.customeheads {
        font-size: 14px;
        line-height: normal;
    }
    .mainBanner {
        background-size: auto 100%;
        background-position: right;
    }
    .mainBanner h1 {
        margin-bottom: 32px;
        font-size: 46px;
    }
    footer .textparts,
    footer .textparts span {
        font-size: 24px;
    }
    .subscriptionletter input::placeholder {
        font-size: 14px;
    }
}
@media screen and (max-width: 992px) {
    header {
        z-index: 9;
        position: relative;
    }
    .sidebanner {
        display: none;
    }
    footer .textparts,
    footer .textparts span {
        font-size: 16px;
    }
    .mainBanner {
    }
    .cardswhite {
        gap: 4px;
    }
    .buildarmy img {
        margin-left: 0px;
    }
    .rowRevreseapplied,
    .aboutus .row {
        flex-direction: column-reverse;
    }
    .cardblock .cardsubs {
        width: 48%;
    }
    footer .whitefooters {
        padding: 16px 20px;
    }
    .problembanner.banner1 img {
        padding-right: 0px;
    }
    .problembanner {
        width: 50%;
        margin: auto;
    }
    .aboutSideImg {
        width: 50%;
        margin: auto;
        margin-top: 16px;
    }
    header .container {
        margin: 0px;
        width: 100%;
        max-width: 100%;
        padding: 0px 15px 0 0;
    }
    header .navigations {
        display: none;
    }
    .togglemenu {
        display: block;
    }
    header .ctas {
        display: flex;
        align-items: center;
    }
    .mainBanner p {
        font-size: 16px;
        max-width: 620px;
        margin-top: 0px;
        margin-bottom: 16px;
    }
    .mainBanner h1 {
        line-height: 48px;
    }
    .stepsofDiagram .col-lg-3.col-sm-12.text-center,
    .stepsofDiagram .col-lg-3.col-sm-12.text-center .stepheading {
        text-align: left;
    }
    .cardblock .cardsubs.active {
        background-color: var(--white);
    }

    .cardblock .cardsubs.active .cardfirstTitle,
    .cardblock .cardsubs.active .cardSecondTitle {
        color: var(--secondary);
    }
    .cardblock .cardsubs.active .geryblocked {
        background-color: #f9fafb;
    }

    .cardblock .cardsubs.active {
        background-image: none;
    }
    .navigations.activated {
        display: block;
        position: absolute;
        background-color: var(--white);
        top: 87px;
        border-radius: 0 0 8px 8px;
        box-shadow: 0px 4px 4px 0px rgba(255, 142, 41, 0.2);
        transition: 0.2s all ease-in-out;
        width: 100%;
    }
    .landingHeader .navigations.activated ul {
        flex-wrap: wrap;
    }
    .landingHeader .navigations.activated ul li,
    .landingHeader .navigations.activated ul li a {
        width: 100%;
        display: block;
    }
    .landingHeader .navigations.activated ul li {
        margin: 0 15px 8px 15px;
        padding-bottom: 8px;
        border-bottom: 1px solid rgb(235, 235, 235);
    }
    .landingHeader .navigations.activated ul li a.active,
    .landingHeader .navigations.activated ul li a:hover {
        border-bottom: 0px;
    }
    .landingHeader .navigations.activated ul {
    }
    .footerbusinessLanding {
        background-color: var(--secondary);
        color: var(--white);
        padding: 16px 10px;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        position: fixed;
        width: -webkit-fill-available;
        width: 100%;
        bottom: 0;
    }
}
@media screen and (max-width: 768px) {
    .left-wrap {
        flex-direction: column;
        flex-direction: column-reverse;
    }
    .right-wrap {
        flex-direction: column;
    }
    .mobile-div > h2 {
        font-size: 20px;
        line-height: 27px;
    }
    .text-div {
        width: 100%;
        font-size: 14px;
        margin-top: 20px;
    }
}

@media screen and (max-width: 721px) {
    .authforms {
        padding: 15px;
    }

    .paymentSection .planInfos .planinfoList ol li {
        padding-left: 4px;
    }

    .paymentSection .planDetails {
        flex-wrap: wrap;
    }

    .paymentSection .planDetails .viewdeatils {
        width: 100%;
        text-align: right;
    }
    .cardblock .cardsubs {
        width: 100%;
    }
    h2.headings,
    h2.headings span {
        font-size: 20px;
    }
    .mapsDesign {
        margin: 20px auto;
    }
    footer .whitefooters {
        flex-wrap: wrap;
        text-align: center;
    }
    .whitefooters .btn-primary {
        text-align: center;
        margin: auto;
        margin-top: 15px;
    }
    .aboutSideImg,
    .problembanner {
        width: 90%;
    }
    p.customeheads,
    .stepsofDiagram .stepcontent {
        font-size: 12px;
        line-height: normal;
    }
    .btnsgroups.aboutUs {
        justify-content: center;
    }
    .mainBanner .btnsgroups .btn {
        min-width: 150px;
    }
    .mainBanner h1 {
        font-size: 32px;
        line-height: 38px;
        margin-bottom: 8px;
    }
    .mainBanner h4 {
        font-size: 20px;
        margin-bottom: 8px;
    }
    .mainBanner p {
        font-size: 14px;
    }
    .landingHeader .sitelogo {
        width: 150px;
        height: auto;
        background-color: var(--secondary);
    }
    .btn-primary,
    .btn-outline {
        padding: 10px 16px;
        height: auto;
    }
    .togglemenu {
        padding: 11px 14px 11px 14px;
    }
    .navigations.activated {
        top: 50px;
    }
    .desktopView {
        display: none;
    }
    .mobileView {
        display: block;
    }
    .referusnetwork {
        background-image: none;
    }
    .mobiletowerIcons img {
        width: 50%;
        margin-bottom: 15px;
    }
    .width-max-btn {
        max-width: 324px;
    }
    .mainBanner .btnsgroups {
        width: 100%;
    }
    .problembanner.banner2 img {
        width: 100%;
    }
    .problembanner.banner2 img {
        padding-left: 0px;
    }
   
}
