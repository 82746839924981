.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    margin-bottom: 10px;
    border-radius: 6px;
  }
  
  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  .custom-pagination {
    margin-top: 25px;
    margin-bottom: 25px;
    display: flex;
    justify-content: end;
  }
  .text-break {
    overflow-wrap: break-word; /* Break long words */
    word-break: break-all; /* Break words at any character */
    white-space: normal; /* Allow normal white space handling */
  }