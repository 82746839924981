.footerbusiness {
    background-color: var(--secondary);
    color: var(--white);
    padding: 16px 0px;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.addverstiseSection {
    background-image: url("../images/business/marketingbackground.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    border-radius: 8px;
    padding: 32px 24px;
}

.addverstiseSection img {
    max-width: 220px;
    width: 100%;
    height: auto;
}

.addverstiseSection p {
    color: #FFF;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 20px;
    margin-top: 0px;
}

.addverstiseSection h4 {
    margin-top: 0px;
    margin-bottom: 5px;
    color: #FFF;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}

/* .blankspace{
    height: 300px;
} */
.blockdesign .blockheading {
    color: #313131;

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 23.308px;
    margin-bottom: 8px;
}

.blockdesign .blockContent {
    color: #575757;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    /* 142.857% */
    margin-bottom: 16px;
}

.backcard {
    border-radius: 8px;
    border: 1px solid #EBEBEB;
    background-color: #FFF;
    padding: 24px 20px;
    margin: 24px auto;
}

.mapsMarketing {
    width: 100%;
    overflow: hidden;
    margin-bottom: auto;

}

.mapsMarketing img {
    width: 100%;
    height:350px;
}

.qrBlock {
    border-radius: 12px;
    border: 1px solid #EEE;
    padding: 16px;
    width: fit-content;
}

.qrBlock img {
    width: 100%;
    max-width: 232px;
}

.blockContent ul li::marker {
    color: var(--primary);

}

.blockContent ul {
    display: flex;
    flex-wrap: wrap;
}
.blockContent> .reward {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: column;
}
.blockContent> .reward li{
    width: 100%;
}

.blockContent ul li {
    width: 50%;
}

.decoLinks {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
}

.lowerprofile {
    display: flex;
    align-items: end;
    justify-content: space-between;
}

.lowerprofile .leftContent {
    display: flex;
    align-items: end;
    gap: 25px
}

.textmarketing .head {
    color: #2F343A;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: auto;
    margin-bottom: 0px;
    /* 150% */
}

.textmarketing .subHead {
    color: #2F343A;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 133.333% */
}

.leftContent .logoholder {
    width: 125px;
    height: 125px;
    padding: 2px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    object-fit: cover;
    border-radius: 8px;
}

.leftContent .logoholder img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.rightContent .socialmedIcons {
    display: flex;
    align-items: center;
    gap: 15px;
}

.rightContent .socialmedIcons a.socialIcons {
    background-color: #D9E1FD;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    padding: 8px 9px;
    text-align: center;
}

.rightContent .socialmedIcons a.socialIcons svg {
    margin: auto;
}

/* .coverpage{
    min-height:200px;
    padding-top: 100px;
    padding-bottom: 16px;
    border-bottom: 1px solid #EBEBEB;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100% 150px;
} */

.coverpage.d-flex{
    display: flex;
    flex-wrap: wrap;
}

.coverpage .left-img-col{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: rgb(255, 244, 237); */
}
.coverpage .left-img-col img{
    border-radius: 3px;
}

.coverpage .brand-icon{
    width: 100%;
    border-radius: 2px;
    text-align: center;
}
.coverpage .brand-icon a {
    text-decoration: none; /* Optional: Removes underline from the link */
    color: inherit; /* Ensures link takes the same color as surrounding text */
  }

.coverpage .brand-icon p {
    font-weight: 500 ;
    margin-bottom: 18px;
    margin-top: 9px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
  }

.coverpage .brand-icon img{
    width: 60px;
    margin-right: 10px;
}

.coverpage .left-img-col img{
    /* width: 100%; */
    height: 150px;
 
 
}

.coverpage .right-img-col{
    width: 50%;
}

.coverpage .right-img-col img{
    width: 100%;
    height: 150px;
}


.footerbusinessLanding {
    background-color: var(--secondary);
    color: var(--white);
    padding: 16px 10px;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* position: fixed; */
    width: -webkit-fill-available;
    width: calc(100%);
    bottom: 0;
}




@media screen and  (max-width:991px) {
    .coverpage{
        background-size: auto 100px;
        /* background-size: cover; */
    }
    .lowerprofile{
        flex-wrap: wrap;
        gap:15px
    }
    .qrBlock{
        margin: auto;
    }
}

@media screen and  (max-width:721px) {
    /* .textmarketing .head{
        font-size: 18px;
    }
    .textmarketing .subHead{
        font-size: 14px;
    } */
    .lowerprofile .leftContent{
        flex-wrap: wrap;
        gap: 10px;
    }
    .coverpage{
        background-size: auto 150px;
    }
    .backcard{
        padding: 12px 10px;
    }
    .blockdesign .blockContent{
        font-size: 12px;
        line-height: normal;
    }
    .blockContent ul li {
        width: 100%;
    }
    .addverstiseSection h4{
        font-size: 16px;
    }
    .addverstiseSection p{
        font-size: 12px;
    }
}

