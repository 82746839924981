/* @import '~antd/dist/antd.css'; */

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "./variables.css";
@import "./grid.css";
@import "./auth.css";
@import "./buttons.css";
@import "./input.css";
@import "./header.css";
@import "./dashboard.css";
@import "./card.css";
@import "./landing.css";
@import "./businessmarketing.css";
@import "./common.css";

@import "./responsive.css";

html {
    scroll-behavior: smooth;
  }
body {
    padding: 0px;
    margin: 0px;
    font-family: "Poppins", system-ui;
    font-style: normal;
    scroll-behavior: smooth;
    overflow-x: hidden;
}
p{
    margin: 0;
    padding: 0;
}
h2, h4{
    margin: 0;
    padding: 0;
}
a{
    text-decoration: none !important;
}