.d-flex {
    display: flex;
}

.join-referrer {
    justify-content: center;
    display: flex;
    font-size: 400;
    align-items: center;
    margin-left: 10px;
}
.join-referrer .btn-primary {
    font-size: 18px;
    height: 60px;
}

.join-referrer p {
    font-size: 12px;
}

.devider {
    margin-left: 20px;
    display: flex;
    align-content: center;
    flex-direction: column;
}
.divider-text {
    font-size: 20px;
    padding: 10px 0px;
}

@media screen and (max-width: 500px) {
    .join-referrer .btn-primary {
        height: auto;
    }
}
