.btn {
    border: 0px;
    outline: none;
    background-color: transparent;
    cursor: pointer;
}

.btn-icons {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid var(--primary)
}

.width-max-btn {
    max-width: 373px;
}

.btn-outline-primary {
    color: var(--primary);
    border: 1px solid var(--primary);
    border-radius: 6px;
    background: transparent;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    box-shadow: none;
    padding: 15px 20px;
    height: 46px;
    transition: 0.2s all;
}

.btn-outline-primary-md {
    color: var(--primary);
    border: 1px solid var(--primary);
    border-radius: 6px;
    background: transparent;
    text-align: center;
    font-size: 10px;
    font-weight: 500;
    padding: 12px 11px;
    height: 22px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s;
}

.btn-outline-primary-md {
    color: var(--primary);
    border: 1px solid var(--primary);
    border-radius: 6px;
    background: transparent;
    text-align: center;
    font-size: 10px;
    font-weight: 500;
    padding: 12px 11px;
    height: 22px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s;
}

.btn-outline-primary-md:hover {
    background-color: var(--primary) !important; /* Set specific hover background color */
    border-color: var(--primary) !important; /* Set specific hover border color */
    color: var(--white) !important; /* Set hover text color */
}

.btn-outline-primary-md:focus, .btn-outline-primary-md:active {
    outline: none;
    background-color: var(--primary) !important; /* Focus and active background color */
    border-color: var(--primary) !important; /* Consistent border on focus and active */
    color: var(--white) !important; /* Focus and active text color */
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25); /* Optional focus shadow */
}

.btn-outline-primary:hover {
    background-color: var(--primarydark);
    color: var(--white);
    transition: 0.2s all;
}