.formControl label {
    color: var(--secondary);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.722px;
    text-transform: capitalize;
}

.formControlDesign label {
    color: var(--secondary) !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    margin-bottom: 0px;
    padding-bottom: 0px !important;
    white-space: nowrap;
}

.formControlDesign input {
    border-radius: 6px;
    border: 1.036px solid rgba(228, 228, 228, 0.90) !important;
    background: var(--white) !important;
    padding: 12px 16px !important;
    width: 100%;
}

.formControlDesign input::placeholder {
    color: rgba(45, 45, 45, 0.70);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.ant-input-outlined:hover,
.ant-input-outlined:focus,
.ant-input-outlined:focus-within,
.ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector,
.ant-select-focused:where(.css-dev-only-do-not-override-vn1oyg).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
    border-color: var(--primary) !important;
    box-shadow: 0 0 0 2px rgba(255, 118, 5, 0.1);
}

.ant-form-vertical .ant-form-item:not(.ant-form-item-horizontal) .ant-form-item-label,
.ant-form-vertical .ant-form-item:not(.ant-form-item-horizontal) .ant-col-24.ant-form-item-label,
.ant-form-vertical .ant-form-item:not(.ant-form-item-horizontal) .ant-col-xl-24.ant-form-item-label {
    padding: 0px;
}
.ant-form-item {
    margin-bottom: 12px !important;
    font-family: "Poppins", system-ui !important;
}

:where(.css-dev-only-do-not-override-vn1oyg).ant-select-single {
    height: 48px;
}

.btn-primary {
    border-radius: 6px;
    background: var(--primary);
    color: var(--white);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    box-shadow: none;
    padding: 15px 20px;
    height: 46px;
}

.btn-primary:hover {
    background-color: var(--primarydark);
}

.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover,
.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):active {
    background-color: var(--primarydark);
}

.ant-select .ant-select-arrow {
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.25);
    font-style: normal;
    line-height: 1;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 50%;
    inset-inline-start: auto;
    inset-inline-end: 15px;
    height: 12px;
    width: 16px;
    margin-top: -6px;
    font-size: 10px;
    pointer-events: none;
    transition: opacity 0.3s ease;
}

.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: var(--primarylight);
}

.formControl label.checkBoxdesign {
    color: var(--darkgrey);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 16px;
    font-family: "Poppins", system-ui !important;
    /* text-decoration-line: underline; */
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
    background-color: var(--primary);
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner,
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
    border-color: var(--primary);
}

.ant-checkbox-checked .ant-checkbox-inner {
    border-color: var(--primary);
    background-color: var(--primary);
}
 




.formControlDesign .ant-select .ant-select-selector, .formControlDesign .ant-select .ant-select-selector{
    border-radius: 6px;
    border: 1.036px solid rgba(228, 228, 228, 0.90) !important;
    background: var(--white) !important;
    padding: 12px 16px !important;
    width: 100%;
    min-height: 48px;
} 
.formControlDesign .ant-select.ant-select-single  .ant-select-arrow{
margin-top: 3px;
}




.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    border-color: var(--primary) !important;
    background-color: var(--primary) !important;
}

.ant-radio-wrapper:hover .ant-radio-inner {
    border-color: var(--primary) !important;
}