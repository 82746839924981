.mainHeader {
    padding: 24px 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.pagetitles {
    color: #000;

    font-size: 31px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
}

.secondmenu .profile .profileImgs {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
    display: block;

}

.secondmenu .profile .profileImgs img {
    width: 100%;
    height: 100%;
}

.notification {
    background-color: var(--secondary);
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    border-radius: 4px;
    padding: 7px 5px;

}

.secondmenu {
    display: flex;
    align-items: center;
}