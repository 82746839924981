section {
    padding: 80px 0;
}

a.btn {
    line-height: 18px;
}

.landIngPage {
    font-family: 'Poppins', system-ui;
    font-style: normal;
    scroll-behavior: smooth;
}

.landingHeader .headerContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.landingHeader .sitelogo {
    width: 260px;
    height: 88px;
}

.landingHeader .sitelogo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.landingHeader .navigations ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style-type: none;
    padding: 0;
}

.landingHeader .navigations ul li {
}

.landingHeader .navigations ul li a {
    text-decoration: none;
    color: var(--secondary) !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0 20px;
    /* transition: 0.5s all ; */
}

.landingHeader .navigations ul li a.active,
.landingHeader .navigations ul li a:hover {
    border-bottom: 3px solid var(--primary);
    /* transition: 0.5s all; */
}

.mainBanner {
    /* background-image: url('../images/landing/banner.svg');  */
    background-image: url('../images/landing/bannernew.png');
    background-repeat: no-repeat;
    background-size: 100%;
    padding: 130px 0;
    background-position: bottom center;
}

.btn-outline {
    color: var(--white);
    border: 1px solid var(--white);
    border-radius: 6px;
    background: transparent;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    box-shadow: none;
    padding: 15px 20px;
    height: 46px;
    transition: 0.2s all;
}

.btn-outline:hover {
    background-color: var(--white);
    color: var(--primary);
    transition: 0.2s all;
}

.mainBanner h1 {
    color: var(--white);
    font-size: 46px;
    font-style: normal;
    font-weight: 700;
    line-height: 65px;
    /* 141.304% */
    text-transform: capitalize;
    max-width: 526px;
    width: 100%;
    margin-top: 0px;
    margin-bottom: 45px;
}

.mainBanner h1 span {
    background-color: var(--primary);
}

.mainBanner h4 {
    color: var(--white);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 0px;
    margin-top: 0px;
}

.mainBanner p {
    color: var(--white);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    max-width: 620px;
    margin-top: 0px;
    margin-bottom: 20px;
    width: 100%;
}

.mainBanner p.lowertext {
    color: var(--white);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
}

.mainBanner p.lowertext span {
    color: var(--primary);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
}

.mainBanner .btnsgroups {
    width: 458px;
}

.mainBanner .btnsgroups .btn {
    margin-right: 25px;
    min-width: 174px;
    margin-bottom: 22px;
}

.aboutSideImg img {
    width: 100%;
}

h2.headings {
    color: var(--secondary);
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 0px;
    margin-bottom: 32px;
}

h2.headings span {
    color: var(--primary);
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.btn-outline.btn-color-outline {
    border-color: var(--primary);
    color: var(--primary);
}

.btn-outline.btn-color-outline:hover {
    background-color: var(--primary);
    color: var(--white);
}

.solution {
    background-image: url('../images/landing/gardsections.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center center;
}

p.customeheads {
    margin-top: 0px;
    overflow: hidden;
    color: var(--secondary);
    text-align: center;
    text-overflow: ellipsis;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    /* 187.5% */
}

.aboutprocess {
    background-image: url('../images/landing/process.svg');
    background-color: rgba(10, 21, 38, 0.18);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center center;
}

.cardswhite {
    padding: 25px 30px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    border-radius: 0px 30px;
    background: var(--white);
    text-align: center;
    margin-bottom: 30px;
    height: 100%;
    transition: 0.2s all;
    cursor: pointer;
}

.cardswhite:hover {
    box-shadow: 0px 4px 4px 0px rgba(255, 142, 41, 0.2);
    transition: 0.2s all;
}

.cardswhite img {
    margin: auto;
}

.cardswhite .title {
    width: 100%;
    text-align: center;
    color: var(--secondary);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.cardswhite .cardContent {
    color: var(--darkgrey);
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.referusnetwork,
.subsplans {
    background-image: url('../images/landing/refernetwork.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center center;
}

.worldwide {
    background-image: url('../images/landing/mapback.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center center;
}

.mapsDesign {
    margin-top: 80px;
    margin-bottom: 80px;
}

footer {
    background-color: var(--secondary);
    color: var(--white);
    position: relative;
}

.fottercontent {
    padding-top: 135px;
    padding-bottom: 60px;
}

.fottercontent .socialIcons a {
    margin-right: 16px;
}

.fottercontent p {
    color: var(--white);
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 21px;
    /* 150% */
}

.fottercontent .links a {
    color: var(--white);
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    /* 150% */
    text-decoration: none;
    display: block;
    cursor: pointer;
    margin-bottom: 12px;
}

.fottercontent .links a:hover {
    text-decoration: underline;
}

.subscriptionletter {
    position: relative;
}

.subscriptionletter input {
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.2);
    padding: 16px;
    width: 100%;
    margin: 0px;
    outline: none;
    border: 0px;
    color: var(--white);
    font-size: 16px;
}

.subscriptionletter input::placeholder {
    color: var(--white);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
}

.subscriptionletter button {
    border-radius: 0px 8px 8px 0px;
    background: var(--white);
    padding: 14px 12px;
    position: absolute;
    height: 50px;
    width: 47px;
    margin: 0px;
    right: 0px;
    border: 0px;
    outline: none;
    top: 0px;
}

footer .copyright {
    padding: 12px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.466);
}

ul.listDesign {
    padding-left: 15px;
    margin-top: 0px;
}

ul.listDesign li {
    color: #909090;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    /* 187.5% */
}

ul.listDesign li::marker {
    color: var(--primary);
    font-size: 1.5em;
}

.problembanner img {
    width: 100%;
}

.problembanner.banner1 img {
    padding-right: 35px;
}

.problembanner.banner2 img {
    padding-left: 35px;
    width: 120%;
}

.sidetextsol h3 {
    color: var(--primary);
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 0px;
    margin-bottom: 16px;
}

.sidetextsol h5 {
    color: var(--secondary);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 0px;
    margin-bottom: 8px;
}

.mt-30 {
    margin-top: 30px;
}

.btnsgroups.aboutUs {
    display: flex;
    gap: 15px;
    margin-left: 15px;
}

.aboutprocess .col-lg-4.col-md-6.col-sm-12 {
    margin-bottom: 15px;
}

section.keyfeatures {
    background-image: url('../images/landing/keyfeaturesbackground.svg');
    background-repeat: no-repeat;
    background-size: 80% 80%;
    background-position: bottom, center;
}

.stepsofDiagram .stepheading {
    overflow: hidden;
    color: var(--primary);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    /* 187.5% */
}

.stepsofDiagram .stepcontent {
    color: var(--secondary);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
}

.buildarmy img {
    margin-left: -50px;
}

.cardblock {
    display: flex;
    gap: 20px;
    align-items: center;
    margin: 60px auto;
}

.cardblock .cardsubs {
    border-radius: 12px;
    background: #fff;
    box-shadow: 0px 2.92px 6.57px 0px rgba(0, 0, 0, 0.05);
    padding: 18px 14px;
    text-align: center;
    min-height: 418px;
}

.cardblock .cardsubs .cardfirstTitle {
    color: var(--secondary);
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 32px;
}

.cardblock .cardsubs .cardSecondTitle {
    color: var(--secondary);
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    /* 60% */
    margin-bottom: 22px;
}

.cardblock .cardsubs .geryblocked {
    border-radius: 8px;
    background: #f9fafb;
    padding: 18px;
    text-align: left;
    min-height: 277px;
}

.cardblock .chooseplan {
    border-radius: 8px;
    border: 1px solid var(--primary);
    background: #fff;
    box-shadow: 0px 2.92px 6.57px 0px rgba(0, 0, 0, 0.05);
    padding: 12px 50px;
    color: var(--primary);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21.9px;
    /* 166.667% */
    cursor: pointer;
    transition: 0.5s all;
}

.cardblock .chooseplan:hover {
    background-color: var(--primary);
    color: var(--white);
    transition: 0.5s all;
}

.cardblock .cardsubs .geryblocked ul li {
    color: var(--secondary);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21.9px;
    /* 166.667% */
    max-width: 220px;
    margin-bottom: 16px;
    position: relative;
}

.cardblock ul.listnew {
    padding-left: 20px;
}

.cardblock ul.listnew li::marker {
    display: none;
    font-size: 0.1px;
}

.cardblock ul.listnew li::before {
    content: url('../images/landing/tickmark.svg');
    position: absolute;
    width: 10px;
    height: 10px;
    left: -25px;
}

.cardblock .cardsubs.active {
    background-color: var(--primary);
}

.cardblock .cardsubs.active {
}

.cardblock .cardsubs.active .cardfirstTitle,
.cardblock .cardsubs.active .cardSecondTitle {
    color: var(--white);
}

.cardblock .cardsubs.active .geryblocked {
    background-color: var(--white);
}

.cardblock .cardsubs.active {
    background-image: url('../images/landing/activecards.svg');
    background-repeat: no-repeat;
    background-size: 100% 90%;
    background-position: bottom, center;
}

.cardblock .cardsubs:hover {
    box-shadow: 0px 4px 4px 0px rgba(255, 142, 41, 0.2);
    transition: 0.2s all;
    cursor: pointer;
}

.referusnetwork {
    background-image: url('../images/landing/dataSignal.svg');
    background-repeat: no-repeat;
    background-size: 80% 80%;
    background-position: bottom, center;
}

footer .textparts {
    color: var(--primary);
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 52px;
    /* 130% */
    text-transform: capitalize;
}

footer .textparts span {
    color: var(--secondary);
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 52px;
}

footer .whitefooters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    background: var(--white);
    box-shadow: 1px 11px 49px 0px rgba(0, 0, 0, 0.09);
    padding: 32px 40px;
}

footer .outerfooter {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    top: -50px;
}

.togglemenu {
    background-color: var(--primary);
    border: 0px;
    outline: none;
    border-radius: 4px;
    padding: 4px 8px;
}

.togglemenu {
    background-color: var(--primary);
    border: 0px;
    outline: none;
    border-radius: 4px;
    padding: 16px 14px 13px 14px;
    margin-right: 8px;
    display: none;
}

.desktopView {
    display: block;
}

.mobileView {
    display: none;
}

.networkCards {
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 0px 4px 0px rgba(255, 142, 41, 0.1),
        0px 0px 4px 0px rgba(255, 142, 41, 0.1);
    padding: 10px;
    margin-bottom: 8px;
}

.networkCards .networkhead {
    color: #050f24;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.networkCards .networkcontent {
    color: #050f24;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.cardblock .cardsubs {
    min-height: 492px;
    display: flex;
    flex-direction: column;
}

.cardblock .cardsubs .geryblocked {
    border-radius: 8px;
    background: #f9fafb;
    padding: 18px;
    text-align: left;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
}

.geryblocked .text-center {
    margin-top: auto;
}

/* new section css  */

.refer-text {
    margin-bottom: 16px;
    font-size: 14px;
    color: var(--secondary);
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.parent-mobile-div {
    margin-top: 50px;
    height: 100%;
}
.mobile-div > h2 {
    margin-bottom: 20px;
    color: var(--secondary);
    line-height: 30px;
    font-weight: 600;
}

.heading-div {
    font-size: 20px;
    font-weight: 600;
}
.right-wrap {
    font-weight: 600;
}
.right-wrap h4 {
    margin-bottom: 10px;
    font-size: 14px;
    color: var(--secondary);
    font-style: normal;
    font-weight: 400;

}
.text-div h4 {
    margin-bottom: 10px;
    font-size: 14px;
    color: var(--secondary);
    font-style: normal;
    font-weight: 400;
  
}
.right-wrap span {
    color: #f47523;
}
.text-div span {
    color: #f47523;
    font-weight: 600;
}
.right-wrap-img {
    width: 417px;
    height: 420px;
}

.mobile-div h2 span {
    color: #f47523;
}
.left-wrap,
.right-wrap {
    display: flex;
}
.text-div {
    width: 50%;
    margin-top: 78px;
}
.logo-text {
    display: flex;
    align-items: center;
    gap: 8px;
    position: absolute;
    left: 43%;
    top: 21%;
}
.left-img {
    height: 14px;
}
.parent-div {
    position: relative;
}
.logo-text p {
    font-size: 14px;
}

.h-auto{
    height: auto !important;
}


@media screen and (max-width: 500px) {

    .mobile-div .right-wrap-img{
        width: auto;
    }

    .text-center{
        text-align: center;
    }
    
}