.header-wrapper {
    background-color: black;
}
.headerContent1 > .logo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.landingHeader .headerContent1 {
    display: flex;
    align-items: center;
    justify-content: center
}

.landingHeader .logo {
    width: 260px;
    height: 100px;
}
p.customeheads1 {
    font-size: 14px;
    line-height: normal;
    margin-bottom: 50px;
}
.cardblock1{
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 1300px) {
    .mainBanner1 {
        background-size: cover;
    }
}
@media screen and (max-width: 1500px) {
    .mainBanner1 {
        background-size: auto 100%;
        background-size: cover;
    }
}

@media screen and (max-width: 1100px) {
   
}
