.ant-tooltip {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .ant-tooltip .ant-tooltip-content {
    margin-left: 10px;
  }

 #businessCategory_help > :where(.css-dev-only-do-not-override-14i19y2).ant-form-item .ant-form-item-explain-error {
    color: #ff4d4f;
    margin-top: 10px;
}

/* Custom CSS */
#businessCategory_help > .ant-form-item-explain-error {
  margin-top: 10px; /* Adjust the margin as needed */
}

:where(.css-dev-only-do-not-override-14i19y2).ant-select-single .ant-select-selector .ant-select-selection-search {
  position: absolute;
  top: 0;
  inset-inline-start: 0px !important;
  inset-inline-end: 0px !important;
  /* padding-left: 10px !important; */
  border: none !important;
  bottom: 0;
}
