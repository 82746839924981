.sidebar {
    background: var(--secondary);
    box-shadow: 3px 0px 15px 0px rgba(0, 0, 0, 0.09);
    width: 260px !important;
    min-height: 100vh;
    padding: 24px 20px;
    position: fixed !important;
}

.sidebar .mainlogo {
    height: fit-content;
    margin-bottom: 30px;
}

.sidebar img {
    width: 100%;
}

nav#sidebar a {
    width: 100%;
    display: flex;
    padding: 10px 15px;
    align-items: center;
    transition: 0.2s all ease-in-out;
}

nav#sidebar a svg {
    margin-right: 8px;
    /* fill: #fff; */
    width: 24px !important;
    /* height: 24px !important; */
    stroke: white;
    stroke-width: 1.2px !important;
}

nav#sidebar a.active {
    background-color: white;
    border-radius: 4px;

}

nav#sidebar a.active span {
    color: var(--secondary);
    font-weight: 400;
}

nav#sidebar a.active svg {
    stroke: var(--secondary);
}

.sidebar .ps-sidebar-container {
    display: flex;
    flex-wrap: wrap;
    gap: 48px;
    height: auto;
}

nav#sidebar {
    width: 100%;
}

.sidebar .navigationMenu {
    width: 100%;
}

.sidebar .heading {
    color: #838383;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    padding: 0 15px;
    margin: 0;
    margin-bottom: 18px;
}

.sidebar nav a {
    text-decoration: none;
}

.sidebar nav a svg {
    width: 18px;
    height: 18px;
}

.sidebar nav a span {
    color: var(--white);
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 27px;


}

.mainheader {
    padding: 25px 30px;
}

.mainheader .mainTitleofPage {
    color: #000;
    font-size: 31px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    /* 87.097% */

}

.profileicon {
    width: 40px;
    height: 40px;

}

button.avtarbutton,
button.notificationbtn {
    padding: 0px;
    margin: 0px;
}

.profileicon img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.notificationblock {
    border-radius: 8px;
    background: #FFFAF1;
    width: 50px;
    height: 50px;
    padding: 12px 8px;
    position: relative;
}

.badegIcon {
    background-color: red;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    font-size: 8px;
    color: white;
    position: absolute;
    right: 8px;
    top: 11px;
}

.cardheads {
    display: flex;
    align-self: center;
    justify-content: space-between;
    width: 100%;
}

.cardTitle {
    color: #0F1D2D;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* 30px */
    letter-spacing: -0.4px;
}

.btn.blackoutlineBtn {}

.accordianmenu .menuItems {
    background-color: transparent;
    border: 0px;
    box-shadow: none;

}

.accordianmenu .menuItems {
    min-height: 47px !important;
    margin-bottom: 0px;
    border: 0px;

}

.accordianmenu .menuItems::before {
    display: none;
}

.accordianmenu .Mui-expanded {
    margin: 0px !important;
    border: 0px;
    border-radius: 7px;
    background: #ECECEC;
    /* min-height: 47px !important; */
}

.accordianmenu .MuiAccordionSummary-content {
    margin: 0px;
}

.accordianmenu .css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
    min-height: 47px;
}

.accordianmenu .MuiAccordionDetails-root {
    padding: 0 34px 16px 34px;
}

.accordianmenu .MuiAccordionDetails-root .sideNavigation {
    opacity: .5;

}

.accordianmenu .MuiAccordionDetails-root .sideNavigation.active {
    opacity: 1;

}

li.notificationdeatils {
    overflow: hidden;
    color: #6E6E71;
    text-overflow: ellipsis;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

a.dropdownmenused {
    overflow: hidden;
    color: #6E6E71;
    text-overflow: ellipsis;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-decoration: none;
}

button.notificationbtn {
    min-width: 50px;
}

button.avtarbutton {
    min-width: 40px;
    margin-left: 15px;
}

.btnsClosedSidebar {
    display: none;
}

.mainBody {
    background-color: var(--primarylight);
    min-height: 100vh;
    flex-grow: 1;
    margin-left: 260px;
}

.main {
    display: flex;
    width: 100%;

}

.contentBody {
    padding: 0 32px;
}