.dashboardCards {
    display: block;
    width: 100%;
    padding: 14px 27px;
    background-color: var(--white);
    border-radius: 10px;
    box-shadow: 0px 0px 4px 0px rgba(255, 142, 41, 0.10), 0px 0px 4px 0px rgba(255, 142, 41, 0.10);
    text-align: center;
    text-decoration: none;
    border: 1px solid;
    border-color: transparent;
    margin-top: 32px;
}

.dashboardCards:hover,
.dashboardCards.active {
    border-color: var(--primary)
}

.dashboardCards span {
    color: var(--secondary);
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
}

.dashboardCards .navigationIcons {
    max-width: 100px;
    margin: 24px auto 12px auto;
}

.dashboardCards .navigationIcons img {
    width: 100%;
    margin: auto
}

